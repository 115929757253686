import { MapPosition, MapPositionCompatible } from '@/types/MapPosition';
import Vsm from '@vsm/vsm';

export function getCurrentPosition(map: Vsm.Map): MapPosition {
    const camera = map.getCamera();

    return {
        zoom: camera.getZoom(),
        center: camera.getCenter(),
        bearing: camera.getBearing(),
        pitch: camera.getPitch()
    };
}

export function isEqualPosition(
    left: MapPosition,
    right: MapPosition
): boolean {
    return (
        left.zoom === right.zoom &&
        left.center.lng === right.center.lng &&
        left.center.lat === right.center.lat &&
        left.pitch === right.pitch &&
        left.bearing === right.bearing
    );
}

export function positionFrom(compatible: MapPositionCompatible): MapPosition {
    const { center, ...others } = compatible;

    return {
        center: Vsm.LngLat.valueOf(center),
        ...others
    };
}
