import { MapContextProps, withMapContext } from '@/MapContext';
import {
    MapControlDockContextProps,
    withMapControlDockContext
} from '@/MapControlDockContext';
import Vsm from '@vsm/vsm';
import React, { ReactNode } from 'react';

type Props = MapContextProps &
    MapControlDockContextProps & {
        control: Vsm.MapControl;
    };

class MapControl extends React.PureComponent<Props> {
    public componentDidMount(): void {
        MapControl._addControl(this.props);
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            prevProps.map !== this.props.map ||
            prevProps.dockName !== this.props.dockName ||
            prevProps.containerKey !== this.props.containerKey
        ) {
            MapControl._removeControl(prevProps);
            MapControl._addControl(this.props);
        } else if (prevProps.control !== this.props.control) {
            this.props.map?.replaceControl(
                this.props.control,
                prevProps.control
            );
        }
    }

    public componentWillUnmount(): void {
        MapControl._removeControl(this.props);
    }

    private static _addControl(props: Readonly<Props>): void {
        props.map?.addControl(props.control, props.dockName);
    }

    private static _removeControl(props: Readonly<Props>): void {
        props.map?.removeControl(props.control);
    }

    public render(): ReactNode {
        return null;
    }
}

export default withMapContext(withMapControlDockContext(MapControl));
