import { MapContextProps, withMapContext } from '@/MapContext';
import { Disable } from '@/types/CommonProps';
import { clearStyle, setStyle, splitClassName } from '@/utils/DomUtils';
import { isPropChanged } from '@/utils/PropUtils';
import Vsm, { Anchor, LngLatCompatible, Point } from '@vsm/vsm';
import React, { CSSProperties, MutableRefObject, ReactNode } from 'react';
import ReactDOM from 'react-dom';

type Props = Disable &
    MapContextProps & {
        className?: string;
        style?: React.CSSProperties;
        forwardedRef?: MutableRefObject<HTMLElement | null>;
        lngLat?: LngLatCompatible;
        offset?: Point;
        anchor?: Anchor;
        hideOnUpdate?: boolean;
        children?: ReactNode;
    };

class CustomMarker extends React.PureComponent<Props> {
    private readonly _marker: Vsm.Marker;
    private readonly _baseClassName: string;

    public static defaultProps = {
        disabled: false
    };

    public constructor(props: Props) {
        super(props);

        const { lngLat, offset, anchor, hideOnUpdate } = props;
        const element = document.createElement('div');

        this._marker = new Vsm.Marker({
            element,
            lngLat,
            offset,
            anchor,
            hideOnUpdate
        });
        this._baseClassName = element.className;
    }

    public componentDidMount(): void {
        const { disabled, map, className, style, forwardedRef } = this.props;

        if (forwardedRef) {
            forwardedRef.current = this._marker.getElement();
        }

        if (!disabled && map) {
            this._marker.setMap(map);
        }

        this._setClassName(className);
        this._setStyle(style);
        this._renderToContainer();
    }

    private _setClassName(value?: string): void {
        const element = this._marker.getElement();
        element.className = this._baseClassName;

        if (value) {
            element.classList.add(...splitClassName(value));
        }

        this._forceUpdateMarker();
    }

    private _setStyle(value?: CSSProperties): void {
        const element = this._marker.getElement();
        clearStyle(element);

        if (value) {
            setStyle(element, value);
        }

        this._forceUpdateMarker();
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        const { disabled, map, className, style, lngLat, offset, anchor } =
            this.props;
        const disableChanged = disabled !== prevProps.disabled;

        if (disabled) {
            if (disableChanged) {
                this._marker.destroy();
            }

            return;
        }

        if (disableChanged || map !== prevProps.map) {
            this._marker.setMap(map || undefined);
        }

        if (disableChanged || isPropChanged(className, prevProps.className)) {
            this._setClassName(className);
        }

        if (disableChanged || isPropChanged(style, prevProps.style)) {
            this._setStyle(style);
        }

        if (disableChanged || isPropChanged(lngLat, prevProps.lngLat)) {
            if (lngLat) {
                this._marker.setLngLat(lngLat);
            } else {
                this._marker.destroy();
            }
        }

        if (disableChanged || isPropChanged(offset, prevProps.offset)) {
            if (offset) {
                this._marker.setOffset(offset);
            } else {
                this._marker.setOffset({ x: 0, y: 0 });
            }
        }

        if (disableChanged || isPropChanged(anchor, prevProps.anchor)) {
            if (anchor) {
                this._marker.setAnchor(anchor);
            } else {
                this._marker.setAnchor('center');
            }
        }

        this._renderToContainer();
    }

    private _forceUpdateMarker(): void {
        this._marker.setAnchor(this._marker.getAnchor());
    }

    public componentWillUnmount(): void {
        this._marker.destroy();

        if (this.props.forwardedRef) {
            this.props.forwardedRef.current = null;
        }
    }

    private _renderToContainer(): void {
        ReactDOM.render(<>{this.props.children}</>, this._marker.getElement());
    }

    public render(): ReactNode {
        return null;
    }
}

export default withMapContext(CustomMarker);
