import { CSSProperties } from 'react';

export function setStyle(
    element: ElementCSSInlineStyle,
    style: Partial<CSSStyleDeclaration> | Partial<CSSProperties>
): void {
    for (const [cssPropName, cssPropValue] of Object.entries(style)) {
        element.style[cssPropName as any] = cssPropValue as any;
    }
}

export function clearStyle(element: HTMLElement): void {
    element.removeAttribute('style');
}

export function splitClassName(value: string): Array<string> {
    return value.split(/\s+/);
}
