import { MapContextProps, withMapContext } from '@/MapContext';
import { Disable } from '@/types/CommonProps';
import Vsm from '@vsm/vsm';
import React, { ReactNode } from 'react';

type Props = Disable &
    MapContextProps & {
        name: Vsm.EventName;
        listener: Vsm.EventListener;
    };

class MapEventListener extends React.Component<Props> {
    public static defaultProps = {
        disabled: false
    };

    private readonly proxyListener: Vsm.EventListener = (event: Vsm.Event) =>
        this.props.listener(event);

    public componentDidMount(): void {
        this._addEventListener(this.props);
    }

    public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        return (
            nextProps.map !== this.props.map ||
            nextProps.name !== this.props.name ||
            nextProps.disabled !== this.props.disabled
        );
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        this._removeEventListener(prevProps);
        this._addEventListener(this.props);
    }

    public componentWillUnmount(): void {
        this._removeEventListener(this.props);
    }

    private _addEventListener(props: Readonly<Props>): void {
        if (!props.disabled) {
            props.map?.on(props.name, this.proxyListener);
        }
    }

    private _removeEventListener(props: Readonly<Props>): void {
        props.map?.off(props.name, this.proxyListener);
    }

    public render(): ReactNode {
        return null;
    }
}

export default withMapContext(MapEventListener);
