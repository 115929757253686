import MapContext from '@/MapContext';
import { ActivatedMapStyle } from '@/types/ActivatedMapStyle';
import { MapPosition } from '@/types/MapPosition';
import { getCurrentPosition } from '@/utils';
import { isPropChanged } from '@/utils/PropUtils';
import Vsm, { InformalObject } from '@vsm/vsm';
import React, {
    CSSProperties,
    MutableRefObject,
    ReactNode,
    RefObject
} from 'react';

type MapBoundsPositionCompatible = {
    bounds: Vsm.LngLatBoundsCompatible;
    padding?: Vsm.PaddingOptions | number;
    bearing?: number;
    pitch?: number;
};

type MapOptions = {
    config: string | Object | null;
    id?: string;
    enlargeScaleFactor?: Vsm.EnlargeScaleFactor;
    interactive?: Vsm.MapInteractive;
    defaultPosition?: Vsm.CameraOptions;
    position?: Vsm.CameraOptions;
    boundsPosition?: MapBoundsPositionCompatible;
    onPositionChange?: (position: MapPosition) => void;
    additionalFuncNames?: Array<Vsm.MapAdditionalFuncName | string>;
    preserveDrawingBuffer?: boolean;
    activatedMapStyle?: ActivatedMapStyle;
    minZoom?: number;
    maxZoom?: number;
    minPitch?: number;
    maxPitch?: number;
    maxBounds?: Vsm.LngLatBoundsCompatible;
    defaultMaxPreparationTimePerFrame?: number;
    emitFirstPaint?: boolean;
    autoStyleLoad?: boolean;
    preloadStyle?: boolean;
    defaultFadeDuration?: number;
    fontScale?: number;
    iconScale?: number;
    customStyleProperties?: InformalObject;
    defaultOptimize?: Vsm.MapOptimize;
};

type Props = MapOptions & {
    className?: string;
    style?: CSSProperties;
    hidden?: boolean;
    tabIndex?: number;
    autoResize?: boolean;
    forwardedRef?: MutableRefObject<Vsm.Map | null>;
    disablePropDeepCompare?: boolean;
    children?: ReactNode;
};

type State = {
    size: { width: number; height: number };
};

class Map extends React.PureComponent<Props, State> {
    private _mapContainerRef: RefObject<HTMLDivElement> = React.createRef();
    private _map: Vsm.Map | null = null;

    public static defaultProps = {
        hidden: false,
        autoResize: true
    };

    public constructor(props: Props) {
        super(props);

        this.state = {
            size: { width: 0, height: 0 }
        };
    }

    public componentDidMount(): void {
        if (!this._mapContainerRef.current) {
            return;
        }

        const container = this._mapContainerRef.current;
        const {
            config,
            id,
            enlargeScaleFactor,
            interactive,
            position,
            boundsPosition,
            defaultPosition,
            additionalFuncNames,
            preserveDrawingBuffer,
            activatedMapStyle,
            minZoom,
            maxZoom,
            minPitch,
            maxPitch,
            maxBounds,
            defaultMaxPreparationTimePerFrame,
            emitFirstPaint,
            autoStyleLoad,
            preloadStyle,
            defaultFadeDuration,
            fontScale,
            iconScale,
            customStyleProperties,
            defaultOptimize
        } = this.props;

        const { center, zoom } = defaultPosition || position || {};
        const { bounds, padding } = boundsPosition || {};
        const { bearing, pitch } =
            defaultPosition || boundsPosition || position || {};

        const map = new Vsm.Map({
            container,
            config,
            id,
            enlargeScaleFactor,
            interactive,
            center,
            zoom,
            bounds,
            padding,
            bearing,
            pitch,
            preserveDrawingBuffer,
            minZoom,
            maxZoom,
            minPitch,
            maxPitch,
            maxBounds,
            maxPreparationTimePerFrame: defaultMaxPreparationTimePerFrame,
            defaultStyle: activatedMapStyle && {
                name: activatedMapStyle.id,
                type: activatedMapStyle.type
            },
            emitFirstPaint,
            autoStyleLoad,
            preloadStyle,
            fadeDuration: defaultFadeDuration,
            fontScale,
            iconScale,
            customStyleProperties,
            optimize: defaultOptimize
        });

        map.on(Vsm.Map.EventNames.Move, () => {
            const { onPositionChange } = this.props;

            if (!onPositionChange) {
                return;
            }

            onPositionChange(getCurrentPosition(map));
        });

        if (additionalFuncNames) {
            map.enable(...additionalFuncNames);
        }

        this._map = map;

        if (this.props.forwardedRef) {
            this.props.forwardedRef.current = map;
        }

        const rect = container.getBoundingClientRect();

        this.setState(() => ({
            size: { width: rect.width, height: rect.height }
        }));
    }

    public componentWillUnmount(): void {
        if (this._map) {
            this._map.destroy();
            this._map = null;

            if (this.props.forwardedRef) {
                this.props.forwardedRef.current = null;
            }
        }
    }

    public componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        if (!this._mapContainerRef.current) {
            return;
        }

        const {
            autoResize,
            config,
            enlargeScaleFactor,
            interactive,
            position,
            boundsPosition,
            additionalFuncNames,
            activatedMapStyle,
            disablePropDeepCompare = false,
            minZoom,
            maxZoom,
            minPitch,
            maxPitch,
            maxBounds,
            autoStyleLoad,
            preloadStyle,
            fontScale,
            iconScale,
            customStyleProperties = {}
        } = this.props;
        const map = this._map;

        if (autoResize) {
            const rect = this._mapContainerRef.current.getBoundingClientRect();

            if (
                prevState.size.width !== rect.width ||
                prevState.size.height !== rect.height
            ) {
                map?.resize();

                this.setState(() => ({
                    size: { width: rect.width, height: rect.height }
                }));
            }
        }

        if (!map) {
            return;
        }

        const hasChanged = (prev: any, current: any): boolean =>
            isPropChanged(prev, current, !disablePropDeepCompare);

        if (enlargeScaleFactor !== prevProps.enlargeScaleFactor) {
            map.setEnlargeScaleFactor(enlargeScaleFactor || 2);
            map.requestRenderFrame();
        }

        if (hasChanged(interactive, prevProps.interactive)) {
            map.setInteractive(interactive !== undefined ? interactive : true);
        }

        if (position && hasChanged(position, prevProps.position)) {
            const camera = map.getCamera();
            let centerChanged = false;

            if (position.center !== undefined) {
                const mapCenter = camera.getCenter();
                const newCenter = Vsm.LngLat.valueOf(position.center);
                centerChanged =
                    newCenter.lng !== mapCenter.lng ||
                    newCenter.lat !== mapCenter.lat;
            }

            if (
                centerChanged ||
                (position.zoom !== undefined &&
                    position.zoom !== camera.getZoom()) ||
                (position.bearing !== undefined &&
                    position.bearing !== camera.getBearing()) ||
                (position.pitch !== undefined &&
                    position.pitch !== camera.getPitch())
            ) {
                map.getCamera().jumpTo(position);
            }
        }

        if (
            boundsPosition &&
            hasChanged(boundsPosition, prevProps.boundsPosition)
        ) {
            if (boundsPosition.bounds !== undefined) {
                map.getCamera().setBounds(boundsPosition.bounds, {
                    ...boundsPosition,
                    duration: 0
                });
            }
        }

        if (minZoom && hasChanged(minZoom, prevProps.minZoom)) {
            map.getCamera().setMinZoom(minZoom);
        }

        if (maxZoom && hasChanged(maxZoom, prevProps.maxZoom)) {
            map.getCamera().setMaxZoom(maxZoom);
        }

        if (minPitch && hasChanged(minPitch, prevProps.minPitch)) {
            map.getCamera().setMinPitch(minPitch);
        }

        if (maxPitch && hasChanged(maxPitch, prevProps.maxPitch)) {
            map.getCamera().setMaxPitch(maxPitch);
        }

        if (maxBounds && hasChanged(maxBounds, prevProps.maxBounds)) {
            map.getCamera().setMaxBounds(maxBounds);
        }

        if (
            hasChanged(
                new Set(additionalFuncNames),
                new Set(prevProps.additionalFuncNames)
            )
        ) {
            if (prevProps.additionalFuncNames) {
                map.disable(...prevProps.additionalFuncNames);
            }

            if (additionalFuncNames) {
                map.enable(...additionalFuncNames);
            }
        }

        if (
            hasChanged(fontScale, prevProps.fontScale) ||
            hasChanged(iconScale, prevProps.iconScale)
        ) {
            map.setPoiScale({ fontScale, iconScale });
        }

        if (
            hasChanged(customStyleProperties, prevProps.customStyleProperties)
        ) {
            map.setCustomStyleProperties(customStyleProperties);
        }

        if (hasChanged(config, prevProps.config)) {
            map.setConfig(config, {
                autoStyleLoad,
                defaultStyle: activatedMapStyle && {
                    name: activatedMapStyle.id,
                    type: activatedMapStyle.type
                },
                preloadStyle
            });
        } else if (
            activatedMapStyle &&
            hasChanged(activatedMapStyle, prevProps.activatedMapStyle)
        ) {
            map.loadStyle(activatedMapStyle.id, activatedMapStyle.type);
        }
    }

    public render(): ReactNode {
        const { className, style, tabIndex, hidden, children } = this.props;

        return (
            <div
                ref={this._mapContainerRef}
                className={className}
                style={{ ...style, display: hidden ? 'none' : style?.display }}
                tabIndex={tabIndex}
            >
                <MapContext.Provider value={{ map: this._map }}>
                    {children}
                </MapContext.Provider>
            </div>
        );
    }
}

export default Map;
