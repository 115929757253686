import Vsm from '@vsm/vsm';
import React, { ComponentType } from 'react';

export type MapContextProps = {
    map?: Vsm.Map | null;
};

const MapContext = React.createContext<MapContextProps>({});

export default MapContext;

export function withMapContext<P extends MapContextProps>(
    WrappedComponent: ComponentType<P>
) {
    return (props: P) => (
        <MapContext.Consumer>
            {context => <WrappedComponent {...context} {...props} />}
        </MapContext.Consumer>
    );
}
