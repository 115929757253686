import MapControl from '@/MapControl';
import { ControlFactoryFunction } from '@/types/ControlFactoryFuction';
import { isPropChanged } from '@/utils/PropUtils';
import Vsm from '@vsm/vsm';
import React, { ComponentType, ReactNode } from 'react';

export type MapControlBinderProps<C extends Vsm.MapControl = Vsm.MapControl> = {
    onCreateControl?: (control: C) => any;
};

type Props<P> = MapControlBinderProps & {
    createControl: ControlFactoryFunction<P>;
};

type State = {
    control: Vsm.MapControl;
};

class MapControlBinder<P extends Props<any>> extends React.Component<P, State> {
    public static defaultProps = {};

    public constructor(props: any) {
        super(props);

        this.state = {
            control: this.props.createControl(this.props)
        };
    }

    public componentDidMount(): void {
        const { createControl, onCreateControl, ...otherProps } = this.props;
        const control = createControl(otherProps);
        this.setState({ control });

        if (onCreateControl) {
            onCreateControl(control);
        }
    }

    public componentDidUpdate(prevProps: Readonly<P>): void {
        const {
            createControl: prevCreateControl,
            onCreateControl: prevOnCreateControl,
            ...prevOtherProps
        } = prevProps;
        const { createControl, onCreateControl, ...otherProps } = this.props;

        if (
            prevCreateControl !== createControl ||
            isPropChanged(prevOtherProps, otherProps)
        ) {
            const control = createControl(otherProps);
            this.setState({ control });

            if (onCreateControl) {
                onCreateControl(control);
            }
        } else if (prevOnCreateControl !== onCreateControl) {
            if (onCreateControl) {
                onCreateControl(this.state.control);
            }
        }
    }

    public render(): ReactNode {
        return <MapControl control={this.state.control} />;
    }
}

export default MapControlBinder;

export function bindMapControl<
    P extends U & MapControlBinderProps<any>,
    C extends Vsm.MapControl,
    U = P
>(factory: ControlFactoryFunction<U, C>): ComponentType<P> {
    return (props: P) => (
        <MapControlBinder {...props} createControl={factory} />
    );
}
