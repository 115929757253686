import { MapContextProps, withMapContext } from '@/MapContext';
import { Disable } from '@/types/CommonProps';
import { isPropChanged } from '@/utils/PropUtils';
import Vsm, { Anchor, LngLatCompatible, Point } from '@vsm/vsm';
import React, { ReactNode } from 'react';

type Props = Disable &
    MapContextProps & {
        lngLat?: LngLatCompatible;
        offset?: Point;
        anchor?: Anchor;
        defaultColor?: string;
        defaultScale?: number;
        hideOnUpdate?: boolean;
    };

class DefaultMarker extends React.PureComponent<Props> {
    private readonly _marker: Vsm.Marker;

    public static defaultProps = {
        disabled: false
    };

    public constructor(props: Props) {
        super(props);

        const {
            lngLat,
            offset,
            anchor,
            defaultColor,
            defaultScale,
            hideOnUpdate
        } = props;

        this._marker = new Vsm.Marker({
            lngLat,
            offset,
            anchor,
            color: defaultColor,
            scale: defaultScale,
            hideOnUpdate
        });
    }

    public componentDidMount(): void {
        const { disabled, map } = this.props;

        if (!disabled && map) {
            this._marker.setMap(map);
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        const { disabled, map, lngLat, offset, anchor } = this.props;
        const disableChanged = disabled !== prevProps.disabled;

        if (disabled) {
            if (disableChanged) {
                this._marker.destroy();
            }

            return;
        }

        if (disableChanged || map !== prevProps.map) {
            this._marker.setMap(map || undefined);
        }

        if (disableChanged || isPropChanged(lngLat, prevProps.lngLat)) {
            if (lngLat) {
                this._marker.setLngLat(lngLat);
            } else {
                this._marker.destroy();
            }
        }

        if (disableChanged || isPropChanged(offset, prevProps.offset)) {
            if (offset) {
                this._marker.setOffset(offset);
            } else {
                this._marker.setOffset({ x: 0, y: 0 });
            }
        }

        if (disableChanged || isPropChanged(anchor, prevProps.anchor)) {
            if (anchor) {
                this._marker.setAnchor(anchor);
            } else {
                this._marker.setAnchor('center');
            }
        }
    }

    public componentWillUnmount(): void {
        this._marker.destroy();
    }

    public render(): ReactNode {
        return null;
    }
}

export default withMapContext(DefaultMarker);
