import Vsm from '@vsm/vsm';
import React, { ComponentType } from 'react';

export type MapControlDockContextProps = {
    dockName?: string;
    containerKey?: string;
};

const MapControlDockContext = React.createContext<MapControlDockContextProps>({
    dockName: Vsm.Map.ControlDockNames.Default
});

export default MapControlDockContext;

export function withMapControlDockContext<P extends MapControlDockContextProps>(
    WrappedComponent: ComponentType<P>
) {
    return (props: P) => (
        <MapControlDockContext.Consumer>
            {context => <WrappedComponent {...context} {...props} />}
        </MapControlDockContext.Consumer>
    );
}
